/**
 * Api base url is server base url.
 */


export const BASE_URL = "https://api.firstdemat.com/"; //URLS.API_URL || "http://node.thenotesgen.com/";
// export const BASE_URL = "http://65.2.35.167:3010/"; //URLS.API_URL || "http://node.thenotesgen.com/";
// export const BASE_URL = "https://api.apnaresearchplus.com/"; //URLS.API_URL || "http://node.thenotesgen.com/";

/**
 * Api version on which we are going to send request
 */
export const VERSION = "";

export const login = "login";
export const check_is_logged_in = "check_is_logged_in";
export const create_lookup = "create/lookup";
export const update_lookup = "update/lookup";
export const get_lookup_type = "get/lookup/type";
export const get_lookup = "get/lookup";
export const change_lookup_status = "change/lookup/status";
export const delete_lookup = "delete/lookup";
export const create_product = "create/product";
export const get_product = "get/product";
export const get_sub_product = "get/sub-product";
export const update_product = "update/product";
export const delete_product = "delete/product";
export const update_user = "update/user";
export const update_profile = "update/profile";
export const delete_user = "delete/user";
export const create_user = "create/users";
export const get_admin_user = "user/list";
export const get_related_admin_user = "related/user/list"
export const send_password_mail = "send/password/mail"
export const department_designation = "related/type/department/designation"
export const get_email_type = "get/master/email/type"
export const update_email_type = "update/email/type/template"
export const reporting_manager = "reporting/manager/list"
export const create_user_password = "create/user/password"
export const set_user_password = "set/user/password"

export const get_employee_user_details = "employee/list";

export const change_user_status = "change/staus/user";

export const get_related_type_designation = "user/designation/department";

export const delete_related_type_designation = "delete/user/designation/department"

export const import_employee_bulk = "import/employee";
export const import_employee_errors = "employee/errors";
export const import_employee_logs = "employee/import/logs";
export const related_employee_error = "related/employee/error";
export const delete_related_employee_error = "delete/related/type/error";
export const assigned_products = "assigned/products";

// leads url
export const leads = 'leads'
export const update_bulk_lead_assign_mode = 'update_bulk_lead_assign_mode';
export const duplicate_leads = 'duplicate_leads'

export const all_leads = "get/all/leads"
export const delete_lead = "delete/lead"
export const lead_notes = "lead/notes"
export const related_lead_notes = "related/lead/notes"
export const get_team_member_list = "get/all/team/member"

export const delete_leads = 'delete/lead'

export const get_all_crm_accounts = 'crm/get/all/account';

export const crm_account = 'crm/account';

export const delete_account = 'crm/delete/account'

export const get_all_crm_contact = 'crm/get/all/contact'

export const crm_contact = 'crm/contact'

export const delete_contact = 'crm/delete/contact';
// deal url
export const get_all_deals_lead = "crm/get/all/deals";
export const deals_lead = "crm/deals";
export const delete_deals_lead = "crm/delete/deals";
export const get_lead_lookup_source_data = "get/lookup/lead/source";
// employee user names

//lead
export const lead_call_summary = 'lead/call';
export const lead_meeting = 'lead/meeting';
export const lead_email = 'lead/email';
export const lead_related_notes = 'related/lead/notes';
export const lead_task = 'lead/task';
export const lead_delete = 'delete/lead/task';
export const lead_get_tasks = 'get/lead/related/tasks';
// ===delete previous deals account id add new to it
export const delete_account_update_id = 'crm/delete/account/update';
export const all_activities = 'lead/activity';
export const lead_stage = 'lead/stage';
export const lead_activity = 'lead/activity';
export const get_lead_employees_name = "get/all/employee/name";
export const get_lead_employees_city = "get/all/employee/city";
export const save_account_deals_id = "crm/foriegn_key/save";
export const import_lead_bulk = "import/lead";
export const lead_import_logs = "lead/import/logs";
export const lead_import_erros = "lead/errors";
export const delete_related_lead_error = "delete/related/type/error/lead";
// employee re-verification
export const employee_re_verification = "employee/re/submission/verify";
export const get_lead_import_related_error = "related/lead/error";

export const activation_mail = "user/activation-mail";

// stock option calls
export const add_calls = "calls";
export const get_calls = "get-calls";
export const edit_calls = "edit-calls";
export const expiry_date = "get_expiry_dates";
export const get_symbols = "symbols";
export const post_symbols = "post-symbols";
export const edit_symbols = "edit-symbols";
export const get_payment = "crm/get_payment";
export const send_payment_link = "crm/order";

export const live_price = "live_price";

//lead calls
export const call_lead = "lead/call_recording";
export const post_call_data = "lead/call_recording_data";

export const logout = "logout";

// Universal Search 
export const universal_search = "universal_search";

// user rights
export const user_rights = "user/rights";

// checkIn / CheckOut
export const check_in = "check_in";
export const check_out = "check_out";

export const check_login_status = "employee/check_login_status";

// Communication contact
export const communication_contact = "get/communication_contact";
export const create_communication_contact = "create/communication_contact";
export const update_communication_contact = "update/communication_contact";
export const delete_communication_contact = "delete/communication_contact";

// Communication group
export const communication_group = "get/group";
export const create_communication_group = "create/group";
export const update_communication_group = "update/group";
export const delete_communication_group = "delete/group";
export const check_whatsapp_group_name = "check/whatsapp_group_name";



// Assigned Communication url
export const createAssignCommunication = "create/assigned_communication";
export const updateAssignedCommunication = "update/assigned_communication";
export const getAssignedCommuniction = "get/assigned_communication";
export const deleteAssignedComunication = "delete/assigned_communication";
export const check_mobile_number = "check/mobile";

// Assigned team member
export const createAssignTeamMember = "create/assigned_team_member";
export const updateAssignedTemMember = "update/assigned_team_member";
export const getAssignedTeamMember = "get/assigned_team_member";
export const deleteAssignedTeamMember = "delete/assigned_team_member";
export const getTeamLeader = "get/team_leader";
export const getTeamMember = "get/team_members";

// Broadcast
export const createBroadcast = "create/broadcast";
export const getBroadcast = "get/broadcast";
export const deleteBroadcast = "delete/broadcast";
export const updateBroadcast = "update/broadcast";
export const getWhatsappTemplateType = "get/whatsapp_template_type";

// whatsapp chat
export const sendMessage = "send/message";
export const chat_history = "get/chat_history";
export const user_chat_history = "get/user_chat_history";

// url to upload file and get its id
export const uploadFile = "upload/image";
export const createTemplate = "create/template";
export const updateTemplate = "update/template";
export const getTemplateFromTable = "get/template_from_table";
export const deleteTemplate = "delete/template";
export const sendBroadcastMessage = "send/broadcast_message";
export const sendTemplate = "send/template";
export const getMobile = "get/contact_numbers";
export const getMetaTemplate = "get/template";
export const getWhatsappNotification = "get/notification";
export const setIsRead = "post/is_read";

// resumable api for getiing upload session
export const getUploadSessionId = "create/session_id";

export const getHandlerId = "create/header_handler";

export const downloadMedia = "download/media";
export const deleteMedia = "delete/media";

export const get_error_import = "import/Lead/getError";

export const pick_lead = "pick/lead"

export const lead_assignment_log = "lead/assignment/log"



// 
export const createContact = "post/createContact"
export const getAllChatContact = "get/allChats"

// Create contact note
export const createContactNotes = "post/createContactNotes";
export const getContactNotes = "get/getContactNotes";
// export const uploadTemplateImage = "/uploadTemplate/image";

// ---------------------------WHATS APP V1----------------------------------------->>>

export const communication_contact_v1 = "get/v1/communication_contact";
export const user_chat_history_v1 = "get/v1/user_chat_history";
export const getTemplateFromTable_v1 = "get/v1/template_from_table";
export const createContact_v1 = "post/v1/createContact";
export const sendTemplate_v1 = "send/v1/template";
export const getAllChatContact_v1 = "get/v1/allChats";
export const createTemplate_v1 = "v1/create/template";
export const sendMessage_v1 = "v1/send/message";
export const createContactNotes_v1 = "v1/post/createContactNotes";
export const getContactNotes_v1 = "get/v1/getContactNotes";
export const uploadFile_v1 = "v1/upload/image";
export const downloadMedia_v1 = "v1/download/media";
export const deleteMedia_v1 = "v1/delete/media";
export const getMetaTemplate_v1 = "get/v1/template";
export const getUploadSessionId_v1 = "v1/create/session_id";
export const getHandlerId_v1 = "v1/create/header_handler";
export const badgeCounter_v1 = "get/v1/badge";
export const updateChatstatus = "post/v1/updatechatstatus";
export const sendToken_v1 = "post/v1/device_token";






//----------------------------------------------Broadcast V1 -------------------------------------

export const getAllBroadcasts_v1 = "v1/get/broadcast";
// export const addBroadcasts_v1 = "v1/create/broadcast";
export const getAllParticipants_v1 = "get/v1/allParticipant";
export const deleteBroadcast_v1 = 'v1/delete/broadcast';
export const createParticipant_v1 = "post/v1/createParticipant"
export const deleteParticipant_v1 = 'v1/delete/participant';
export const sendBroadcastTemplate_v1 = 'v1/send/broadcast_message';
export const getAllChatTemplate_v1 = 'v1/get/chat_by_group';
export const scheduleBroadcast_v1 = 'v1/schedule/broadcast';
export const importBulkContact_v1 = 'v1/import-excel';
export const addBroadcasts_v1 = "v1/create/whatsaap/group";
export const getScheduledBroadcast_v1 = "get/v1/scheduled_broadcast_template";
export const deleteScheduledBroadcast_v1 = "v1/delete/scheduled_broadcast_template";

//------------------------------Delete Broadcast-----------------

export const deleteTemplate_v1 = "v1/delete/template";
export const updateTemplate_v1 = "v1/update/template";

//-----------------------------Get All Customers-----------------

export const getAllCustomers = "v1/get/allcustomers";
export const getGroupCustomer = "v1/get/group_customers"
export const getAllCustomersForExport = 'v1/get/customerexport'



export const getQuickReply_v1 = "v1/get/quickReply";
export const addQuickReply_v1 = "v1/create/quickReply";
export const editQuickReply_v1 = "v1/update/quickReply";
export const deleteQucickReply_v1 = "v1/delete/quickReply";




